import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Admin } from './admin/Admin';
import { AdminRoute } from './components/AdminRoute';
import { DownloadPopup } from './components/DownloadPopUp';
import { LiveFeedbackPopup } from './components/LiveFeedBackPopup';
import { PopUp } from './components/PopUp';
import { SelectLevelPopUp } from './components/SelectLevelPopUp';
import { Router } from './pages/Router';
import { PaymentContainer } from './plugins/payment/payment';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { SelectLevelNoTestPopUp } from './components/SelectLevelNoTestPopUp';

declare global {
  interface Window { kakaoPixel: any; }
}

const tagManagerArgs = {
   gtmId: 'GTM-WQKXR2J'
}

TagManager.initialize(tagManagerArgs);
ReactGA.initialize([
  {
    trackingId: 'G-11CC0KLWE4', // GA
  },
  {
    trackingId: 'AW-10905165221',
  },
]);
function App() {
  return (
    <BrowserRouter>
      <PaymentContainer.ContextProvider>
        <PopUp />
        <LiveFeedbackPopup />
        <DownloadPopup />
        <SelectLevelPopUp />
        <SelectLevelNoTestPopUp />
        <Switch>
          <AdminRoute path="/admin" component={Admin} />
          <Route path="/" component={Router} />
        </Switch>
      </PaymentContainer.ContextProvider>
    </BrowserRouter>
  );
}

export default App;
