import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useId } from 'react-id-generator';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { H5 } from '../../components/H5';
import { MobileBackButton } from '../../components/MobileBackButton';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import ChannelService from '../../plugins/ChannelService';

interface FormValues {
  phoneNumber: string;
}

export const PasswordFindPhonePage = () => {
  const { push } = useHistory();
  const [id] = useId(1, 'phonenumber');
  const [number1, setNumber1] = useState<string>('');
  const [number2, setNumber2] = useState<string>('');
  const [number3, setNumber3] = useState<string>('');
  const { handleSubmit, setValue } = useForm<FormValues>();

  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CHANNEL_IO_KEY,
    mobileMessengerMode: 'iframe',
    zIndex: 100,
    customLauncherSelector: '#cannelIo-button5',
    profile: {
      name: '비회원',
      mobileNumber: '-',
      email: '-',
      avatarUrl: '-',
    },
  });

  return (
    <div className="max-w-sm mx-auto md:pt-14 px-4">
      <MobileBackButton />
      <H5 className="pb-8">휴대폰 번호로 비밀번호 찾기</H5>
      <form
        onSubmit={handleSubmit((data) => {
          data.phoneNumber = number1 + number2 + number3;
          api
            .post('users/find-password', { phoneNumber: data.phoneNumber })
            .then(() => {
              setValue('phoneNumber', number1 + number2 + number3);
              push('/password/find/sent', { phoneNumber: data.phoneNumber });
            })
            .catch(() => alert('등록된 유저가 아닙니다. 다시 시도해주세요.'));
        })}
      >
        <div className="grid grid-cols-3 gap-4 pb-4">
          <TextField
            id={id}
            type="tel"
            pattern="[0-9]{3}"
            maxLength={3}
            placeholder="010"
            value={number1}
            onChange={(e) => {
              setNumber1(e.target.value);
              e.target.value = `${e.target.value}${number2}${number3}`;
            }}
          />
          <TextField
            type="tel"
            pattern="[0-9]{4}"
            maxLength={4}
            placeholder="0000"
            value={number2}
            onChange={(e) => {
              setNumber2(e.target.value);
              e.target.value = `${number1}${e.target.value}${number3}`;
            }}
          />
          <TextField
            type="tel"
            pattern="[0-9]{4}"
            maxLength={4}
            placeholder="0000"
            value={number3}
            onChange={(e) => {
              setNumber3(e.target.value);
              e.target.value = `${number1}${number2}${e.target.value}`;
            }}
          />
        </div>

        <div className="flex flex-col space-y-4">
          <Button
            className="filled-primary-1 mt-10 w-full"
            text="휴대폰 번호로 찾기"
            disabled={!number1 || !number2 || !number3}
          />
          <div id="cannelIo-button5" className="text-center cursor-pointer">
            고객센터 문의
          </div>
        </div>
      </form>
    </div>
  );
};
