import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Tab } from '../components/Tab';
import { PricingRegularPage } from './PricingRegularPage';
import { PricingTrialPage } from './PricingTrialPage';
import { PricingSpecialPage } from './PricingSpecialPage';

const tabs = [
  { title: '정규 수업', path: '/pricing/regular' },
  // { title: '체험 수업', path: '/pricing/trial' },
  { title: '특강 수업', path: '/pricing/special' },
];

export const PricingPage = () => {
  return (
    <div className="border-t border-gray-100">
      <div className="mx-auto px-4 pt-4 w-full max-w-5xl">
        <div className="flex md:pl-24 space-x-4">
          {tabs.map((t) => (
            <Tab key={t.title} title={t.title} path={t.path} />
          ))}
        </div>
      </div>
      <div>
        {/* <div className="flex md:flex-row flex-col min-h-screen-10 gap-10 Hero-Img-pa">
        <img
          src={HeroImg}
          alt="pricing page"
          className="h-full md:w-1/2 object-top object-cover Hero-Img"
        /> */}

        <Switch>
          <Route path="/pricing/regular" component={PricingRegularPage} />
          {/* <Route path="/pricing/trial" component={PricingTrialPage} /> */}
          <Route path="/pricing/special" component={PricingSpecialPage} />
          <Redirect from="/" to="/pricing/regular" />
        </Switch>
      </div>
    </div>
  );
};
