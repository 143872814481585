import moment from 'moment';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Avatar } from '../../components/Avatar';
import { H5 } from '../../components/H5';
import { H6 } from '../../components/H6';
import { Pagination } from '../../components/Pagination';
import { Section } from '../../components/Section';
import { useAuth, useQueryString } from '../../hooks';
import { apiUrl } from '../../plugins/axios';
import ChannelService from '../../plugins/ChannelService';
import { fetcher } from '../../plugins/react-query';
import {
  Paginated,
  Payment,
  PaymentMethod,
  PaymentState,
  User,
} from '../../types';

export const PaymentsPage = () => {
  const { authenticated } = useAuth();
  const { data: user } = useQuery<User>(`/users/me`, fetcher, {
    enabled: authenticated,
  });
  const paymentsQueryString = useQueryString({
    filter: { userId: user?.id },
    sort: { id: 'ASC' },
  });

  const { data: payments } = useQuery<Paginated<Payment>>(
    `/payments${paymentsQueryString}`,
    fetcher,
    {
      enabled: !!user,
    }
  );

  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CHANNEL_IO_KEY,
    mobileMessengerMode: 'iframe',
    zIndex: 100,
    customLauncherSelector: '#cannelIo-button3',
    profile: {
      name: user ? user.name : '비회원',
      mobileNumber: user ? user.phoneNumber : '-',
      email: user ? user.email : '-',
      avatarUrl: user?.avatar ? apiUrl(user.avatar) : '-',
    },
  });

  return (
    <>
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row justify-between md:items-center pb-4">
        <H5>배송·결제 내역</H5>
        <p className="text-13 text-gray-500 flex">
          취소 및 환불은
          <p id="cannelIo-button3" className="font-bold ml-1 cursor-pointer">
            문의하기
          </p>
          를 클릭해주세요.
        </p>
      </div>

      {payments?.items.map((payment) => {
        return (
          <div className="bg-white rounded-md shadow-3xl p-6" key={payment.id}>
            <Section className="flex justify-between items-center">
              <div className="flex space-x-2">
                <div
                  className={`border-primary-2 border-2 wh-14 md:wh-20 rounded-full p-1`}
                >
                  <Avatar
                    src={apiUrl(payment.student.avatar)}
                    className="wh-11 md:wh-17"
                  />
                </div>

                <div className="flex flex-col space-y-1">
                  <p className="text-17">{payment.student.name}</p>

                  <div className=" bg-secondary-1 text-primary-1 text-12 rounded-md px-2 py-1">
                    {
                      payment?.student?.testResults[
                        payment?.student?.testResults?.length - 1
                      ].level?.name
                    }
                  </div>
                </div>
              </div>

              <div className="text-blue-500 text-right md:text-left">
                {payment.paymentState === PaymentState.PAID ||
                payment.paymentState === PaymentState.WAITING_FOR_DELIVERY ||
                payment.paymentState ===
                  PaymentState.WAITING_FOR_CONSULTATION ||
                payment.paymentState === PaymentState.LEAVING ||
                payment.paymentState === PaymentState.IN_TRANSIT ||
                payment.paymentState === PaymentState.DELIVERED
                  ? '결제완료'
                  : payment.paymentState === PaymentState.CANCELED
                  ? '취소'
                  : payment.paymentState === PaymentState.WAIT
                  ? '입금대기'
                  : ''}
                <br className="md:hidden" />
                {payment.bookIncluded || payment.penIncluded
                  ? '(배송상품 있음)'
                  : '(배송상품 없음)'}
              </div>
            </Section>

            <div className="border-b border-gray-50 my-8 -mx-5" />
            <Section>
              <div className="flex space-x-2 items-center pb-6">
                <H6>
                  {!payment.totalAmount
                    ? '체험'
                    : payment.isTrialPackage
                    ? `${payment.productAmount}회 체험팩`
                    : payment.productAmount
                    ? `${payment.productAmount}회`
                    : '보강권'}
                </H6>
                <span className="text-gray-400">
                  {`(${payment.bookIncluded ? '교재포함' : '교재미포함'} ∙ ${
                    payment.penIncluded ? '토킹펜 포함' : '토킹펜 미포함'
                  } )`}
                </span>
              </div>

              <div className="space-y-2">
                <InfoItem
                  title="주문일자"
                  description={moment(payment.createdAt).format(
                    'YYYY.MM.DD (a HH시 mm분)'
                  )}
                />
                <InfoItem
                  title="주문번호"
                  description={payment.pgOrderNumber}
                />
                {(payment.bookIncluded || payment.penIncluded) && (
                  <InfoItem
                    title="송장번호"
                    description={payment?.trackingNumber}
                  />
                )}
                <InfoItem
                  title="주문 상태"
                  description={`
                ${
                  payment.paymentState === PaymentState.PAID ||
                  payment.paymentState ===
                    PaymentState.WAITING_FOR_CONSULTATION ||
                  payment.paymentState === PaymentState.IN_TRANSIT ||
                  payment.paymentState === PaymentState.DELIVERED
                    ? '결제완료'
                    : payment.paymentState === PaymentState.CANCELED
                    ? '취소'
                    : payment.paymentState === PaymentState.WAIT
                    ? '입금대기'
                    : payment.paymentState === PaymentState.WAITING_FOR_DELIVERY
                    ? '배송준비'
                    : payment.paymentState === PaymentState.LEAVING
                    ? '출고완료'
                    : ''
                }
                ${
                  payment.bookIncluded || payment.penIncluded
                    ? '(배송상품 있음)'
                    : '(배송상품 없음)'
                }
                `}
                />
                <InfoItem
                  title="자녀 이름"
                  description={payment.student.name}
                />
                <InfoItem
                  title="결제 금액"
                  description={`
                ${String(!payment.totalAmount ? 0 : payment.paidAmount).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}
                />
                <InfoItem
                  title="쿠폰"
                  description={
                    (payment.couponItems && payment.couponItems.length > 0) ||
                    payment.couponItemId
                      ? `${String(payment.discountedAmount).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ','
                        )}원`
                      : '-'
                  }
                />
                <InfoItem
                  title="결제수단"
                  description={
                    payment.paymentMethod === PaymentMethod.BANK
                      ? '계좌이체'
                      : payment.paymentMethod === PaymentMethod.CARD
                      ? `카드결제`
                      : // ∙(${payment.cardName})
                        // : payment.paymentMethod === PaymentMethod.KPAY
                        // ? '카카오페이'
                        // : payment.paymentMethod === PaymentMethod.NPAY
                        // ? '네이버페이'
                        '-'
                  }
                />
              </div>
            </Section>
          </div>
        );
      })}
      <Pagination className="border-t">
        <Pagination.Label
          count={payments?.items.length || 0}
          total={payments?.total || 0}
        />
        <Pagination.Nav
          basePath="/parent/payments"
          total={payments?.total || 0}
        />
      </Pagination>
    </>
  );
};

interface InfoItemProps {
  title?: string;
  description?: string;
}

const InfoItem: FC<InfoItemProps> = ({ title, description }) => {
  return (
    <div className="flex text-12">
      <p className="w-20">{title}</p>
      <p className="flex-1">{description}</p>
    </div>
  );
};
