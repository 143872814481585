import React, { ButtonHTMLAttributes, FC } from 'react';

interface LevelTabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  title: string;
  isNew?: boolean;
}

export const LevelTab: FC<LevelTabProps> = ({
  isNew,
  selected,
  title,
  ...props
}) => {
  return (
    <button
      className={`mb-4 px-4 py-2 flex space-x-2 rounded-full ${
        selected ? 'bg-gray-800' : 'bg-gray-150'
      } `}
      {...props}
    >
      <p className={`${selected ? 'text-white' : 'text-gray-400'}`}>{title}</p>

      {isNew && (
        <div className="bg-brand-1 rounded-full wh-5 text-white text-11 grid place-content-center">
          N
        </div>
      )}
    </button>
  );
};
