import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';

interface EventModalProps {
  onClose: () => void;
  buttonTitle: string;
  secondaryButtonTitle?: string;
  secondaryOnClose?: () => void;
}

export const EventModal: React.FC<EventModalProps> = ({
  onClose,
  buttonTitle,
  secondaryButtonTitle,
  secondaryOnClose,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center px-4 popup_wrap">
      <div className="w-full md:w-1/3 max-w-md align-middle inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all">
        <Link to="/events/10" target="_blank">
          <img src="/event-img.jpg" alt="event-img" className="w-full" />
        </Link>
        <div className="flex">
          {secondaryButtonTitle && secondaryOnClose && (
            <Button
              type="button"
              className="text-md w-full rounded-none bg-white text-primary-1"
              onClick={secondaryOnClose}
            >
              {secondaryButtonTitle}
            </Button>
          )}

          <Button
            type="button"
            className="text-md w-full rounded-none text-white bg-primary-1"
            onClick={onClose}
          >
            {buttonTitle}
          </Button>
        </div>
      </div>
    </div>
  );
};
