import React, { useEffect, useState } from 'react';
import { Button } from '../components/Button';
import { H5 } from '../components/H5';
import { PricingCard } from '../components/PricingCard';
import { Section } from '../components/Section';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import {
  Level,
  LiveClass,
  Product,
  ProductLiveType,
  ProductType,
  Student,
} from '../types';
import { useHistory } from 'react-router';
import { popupState, studentState, testResultState } from '../plugins/ridge';
import { useAuth } from '../hooks';
import { usePopup } from '../components/PopUp';
import { LevelTab } from '../components/LevelTab';
import TextbookIMG from '../assets/img/pricing-textbook.png';
import PenIMG from '../assets/img/pricing-pen.png';
import HeroImg from '../assets/img/pricing-hero-08.png';

export const PricingRegularPage = () => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const { requireLoginPopup, addStudentPopup, requireLevelTestPopup } =
    usePopup();
  const student = studentState.useValue();
  const testResult = testResultState.useValue();
  const [currentStudent, setCurrentStudent] = useState<Student>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [productSelected, setProductSelected] = useState<boolean>(false);
  const [bookIncluded, setBookIncluded] = useState<boolean>();
  const [bookSelected, setBookSelected] = useState<boolean>(false);
  const [penIncluded, setPenIncluded] = useState<boolean>();
  const [penSelected, setPenSelected] = useState<boolean>(false);
  const [selectLevel, setSelectLevel] = useState<string>('low');

  const { data: products } = useQuery<Product[]>('/products/open', fetcher);
  const { data: nextLevel } = useQuery<Level>(
    `/levels/${student?.nextLevelId}`,
    fetcher,
    { enabled: !!student?.nextLevelId }
  );

  const { data: remainingLiveClasses } = useQuery<LiveClass[]>(
    `/live-classes/remaining?nextBookId=${student?.nextBookId}`,
    fetcher,
    { enabled: !!student?.nextBookId }
  );

  const bookProduct = products?.find(
    (p) => p.type === ProductType.BOOK && selectedProduct?.amount === p.amount
  );
  const penProduct = products?.find((p) => p.type === ProductType.PEN);

  // 3, 4단계 오픈으로 인해 책 무료제공 종료
  // const bookMustBeIncluded =
  // productSelected && selectedProduct?.liveType === ProductLiveType.SECOND;

  const freePen = selectedProduct?.freePen === true;

  // useEffect(() => {
  //   if (bookMustBeIncluded) {
  //     setBookIncluded(true);
  //     setBookSelected(true);
  //   }
  // }, [bookMustBeIncluded]);

  useEffect(() => {
    if (freePen) {
      setPenIncluded(true);
      setPenSelected(true);
    }
  }, [freePen]);

  useEffect(() => {
    if (student) {
      setCurrentStudent(student);
    }
  }, [student]);

  useEffect(() => {
    if (currentStudent !== student) {
      setSelectedProduct(undefined);
      setProductSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 3, 4단계 오픈으로 인해 팝업 X
  // useEffect(() => {
  //   if (nextLevel) {
  //     setSelectLevel(
  //       nextLevel?.name === 'Level 1' || nextLevel?.name === 'Level 2'
  //         ? 'low'
  //         : 'height'
  //     );
  //   }
  //   if (
  //     (selectLevel === 'height' &&
  //       (nextLevel?.name === 'Level 3' || nextLevel?.name === 'Level 4')) ||
  //     (!authenticated && selectLevel === 'height')
  //   ) {
  //     popupState.set({
  //       title: '3단계 및 4단계 구매안내',
  //       secondBody: `\n튼튼영어라이브 3단계 및 4단계 수업권을 구매한 회원에게는\n교재패키지(페이퍼북 및 활동자료)와 디지털라이브러리가 제공됩니다.\n*교재 1권 당 2회의 회상수업이 진행됨(주2회 수업권장)\n*온라인 일일학습 없음`,
  //       primaryText: '확인',
  //     });
  //   }
  // }, [authenticated, nextLevel, selectLevel]);

  const installment =
    selectedProduct?.amount === 12
      ? 3
      : selectedProduct?.amount === 24
      ? 6
      : 12;

  return (
    <div className="w-full">
      <div className="Hero-Img-pa">
        <img src={HeroImg} alt="pricing page" />
      </div>
      <div className="pp_page">
        {/* <Section className="max-w-sm mx-auto w-full space-y-6 px-4"> */}
        <Section className="max-w-sm mx-auto space-y-6 px-4">
          <H5 className="pb-2 pr-tit-pa">정규수업 신청</H5>
          <div className="flex space-x-4 border-b border-gray-50">
            {/* <div className="font-nanum step-notice">
              <p className="tit">수업 안내</p>
              <p>- 1/2단계는 권당 1회 수업, 3/4단계는 권당 2회 수업 진행</p>
              <p>- 3/4단계는 1주 2회 수강 및 교재 구매 권장</p>
            </div> */}
            {/* {[
              { id: 1, title: '1・2단계', value: 'low' },
              { id: 2, title: '3・4단계', value: 'height' },
            ].map((l) => (
              <LevelTab
                key={l.id}
                title={l.title}
                selected={selectLevel === l.value}
                onClick={() => {
                  if (
                    (nextLevel?.name === 'Level 1' ||
                      nextLevel?.name === 'Level 2' ||
                      !authenticated ||
                      !testResult ||
                      !student?.isTested) &&
                    l.value === 'low'
                  ) {
                    setSelectLevel('low');
                    setSelectedProduct(undefined);
                    setProductSelected(false);
                    setBookIncluded(undefined);
                    setBookSelected(false);
                    setPenIncluded(undefined);
                    setPenSelected(false);
                  } else if (
                    (nextLevel?.name === 'Level 3' ||
                      nextLevel?.name === 'Level 4' ||
                      !authenticated ||
                      !testResult ||
                      !student?.isTested) &&
                    l.value === 'height'
                  ) {
                    setSelectLevel('height');
                    setSelectedProduct(undefined);
                    setProductSelected(false);
                    setBookIncluded(undefined);
                    setBookSelected(false);
                    setPenIncluded(undefined);
                    setPenSelected(false);
                  } else {
                    popupState.set({
                      title: '수강 가능한 레벨이 아닙니다.',
                      body: '해당 수업을 수강 할 수 있는 레벨이 아닙니다.',
                      primaryText: '확인',
                    });
                  }
                }}
              />
            ))} */}
          </div>

          {/* products */}
          <div className="flex justify-between">
            <p className="font-nanum text-17">
              {productSelected
                ? selectedProduct?.name
                : '구매를 원하는 수업횟수를 골라주세요!'}
            </p>
            <button
              className={`font-bold ${
                selectedProduct ? 'text-primary-1' : 'text-gray-200'
              }`}
              disabled={!selectedProduct}
              onClick={() => {
                if (!productSelected) {
                  setBookIncluded(undefined);
                  setBookSelected(false);
                  setPenIncluded(undefined);
                  setPenSelected(false);
                }
                setProductSelected(!productSelected);
              }}
            >
              {productSelected ? '변경' : '선택'}
            </button>
          </div>
          {!productSelected &&
            (authenticated && student && testResult
              ? (
                  products
                    ?.filter((p) => {
                      if (
                        (remainingLiveClasses ?? [])?.length === 6 &&
                        p.amount <= (remainingLiveClasses ?? [])?.length
                      ) {
                        return p;
                      } else {
                        // return p.amount !== 6;
                        return true;
                      }
                    })
                    .filter(
                      (p) =>
                        p.type === ProductType.LIVE &&
                        p.liveType !== ProductLiveType.TRIAL &&
                        p.liveType === nextLevel?.productLiveType &&
                        p.amount <= (remainingLiveClasses ?? [])?.length
                    ) ?? []
                )
                  .sort((a, b) => a.finalPrice - b.finalPrice)
                  .map((product) => (
                    <PricingCard
                      key={product.id}
                      product={product}
                      nextLevel={nextLevel}
                      selected={product.id === selectedProduct?.id}
                      onClick={() => {
                        if (!productSelected) {
                          if (selectLevel === 'height') {
                            setBookIncluded(true);
                            setBookSelected(true);
                            setPenIncluded(undefined);
                            setPenSelected(false);
                            setSelectedProduct(product);
                          } else {
                            setBookIncluded(undefined);
                            setBookSelected(false);
                            setPenIncluded(undefined);
                            setPenSelected(false);
                            setSelectedProduct(product);
                          }
                        }
                        setProductSelected(!productSelected);
                      }}
                    />
                  ))
              : selectLevel === 'low'
              ? (
                  products?.filter(
                    (p) =>
                      p.type === ProductType.LIVE &&
                      p.liveType !== ProductLiveType.TRIAL &&
                      p.liveType === ProductLiveType.FIRST //&&
                    // p.amount !== 6
                  ) ?? []
                )
                  .sort((a, b) => a.finalPrice - b.finalPrice)
                  .map((product) => (
                    <PricingCard
                      key={product.id}
                      product={product}
                      selected={product.id === selectedProduct?.id}
                      onClick={() => {
                        if (!productSelected) {
                          setBookIncluded(undefined);
                          setBookSelected(false);
                          setPenIncluded(undefined);
                          setPenSelected(false);
                          setSelectedProduct(product);
                        }
                        setProductSelected(!productSelected);
                      }}
                    />
                  ))
              : (
                  products?.filter(
                    (p) =>
                      p.type === ProductType.LIVE &&
                      p.liveType !== ProductLiveType.TRIAL &&
                      p.liveType === ProductLiveType.SECOND // &&
                    // p.amount !== 6
                  ) ?? []
                )
                  .sort((a, b) => a.finalPrice - b.finalPrice)
                  .map((product) => (
                    <PricingCard
                      key={product.id}
                      product={product}
                      selected={product.id === selectedProduct?.id}
                      onClick={() => {
                        if (!productSelected) {
                          setBookIncluded(undefined);
                          setBookSelected(false);
                          setPenIncluded(undefined);
                          setPenSelected(false);
                          setSelectedProduct(product);
                        }
                        setProductSelected(!productSelected);
                      }}
                    />
                  )))}

          {/* books */}
          {productSelected && (
            <>
              <div className="flex justify-between">
                <p className="font-nanum text-17">
                  {bookSelected
                    ? bookIncluded
                      ? bookProduct?.name
                      : '교재 선택 안함'
                    : '교재 구매를 추가할까요?'}
                </p>
                <button
                  className={`font-bold ${
                    bookIncluded !== undefined
                      ? 'text-primary-1'
                      : 'text-gray-200'
                  }`}
                  disabled={bookIncluded === undefined}
                  onClick={() => {
                    if (!bookSelected) {
                      setPenIncluded(undefined);
                      setPenSelected(false);
                    }
                    setBookSelected(!bookSelected);
                  }}
                >
                  {bookSelected ? '변경' : '선택'}
                </button>
              </div>
              {selectedProduct && !bookSelected && (
                <>
                  <div className="bg-secondary-1 rounded-xl p-4 flex space-x-4">
                    <img src={TextbookIMG} alt="" className="w-full h-full" />

                    <p className="text-13">
                      스토리북과 짝꿍 워크북까지 함께 구성되어있어요! 직접
                      영어를 써보며 학습하고 싶은 꼼꼼한 회원들에게 추천해요.
                    </p>
                  </div>
                  <PricingCard
                    product={bookProduct}
                    selected={bookIncluded === true}
                    onClick={() => {
                      if (!bookSelected) {
                        if (freePen) {
                          setPenIncluded(true);
                          setPenSelected(true);
                          setBookIncluded(true);
                        } else {
                          setPenIncluded(undefined);
                          setPenSelected(false);
                          setBookIncluded(true);
                        }
                      }
                      setBookSelected(!bookSelected);
                    }}
                  />
                  <PricingCard
                    selected={bookIncluded === false}
                    onClick={() => {
                      if (!bookSelected) {
                        if (freePen) {
                          setPenIncluded(true);
                          setPenSelected(true);
                          setBookIncluded(false);
                        } else {
                          setPenIncluded(undefined);
                          setPenSelected(false);
                          setBookIncluded(false);
                        }
                      }
                      setBookSelected(!bookSelected);
                    }}
                  />
                </>
              )}
            </>
          )}

          {/* pen */}
          {productSelected && bookSelected && (bookIncluded || freePen) && (
            <>
              <div className="relative flex justify-between">
                <div className="flex items-center space-x-2">
                  <p className="font-nanum text-17">
                    {penSelected
                      ? penIncluded
                        ? penProduct?.name
                        : '스마트 토킹펜 선택 안함'
                      : '스마트 토킹펜을 추가할까요?'}
                  </p>
                </div>

                <button
                  className={`font-bold ${
                    penIncluded !== undefined
                      ? 'text-primary-1'
                      : 'text-gray-200'
                  }`}
                  disabled={penIncluded === undefined || freePen}
                  onClick={() => setPenSelected(!penSelected)}
                >
                  {penSelected ? (freePen ? '무료 포함' : '변경') : '선택'}
                </button>
              </div>

              {selectedProduct && bookSelected && !penSelected && (
                <>
                  <div className="bg-secondary-1 rounded-xl p-4 flex space-x-4">
                    <img src={PenIMG} alt="" className="w-full object-cover" />

                    <p className="text-13">
                      튼튼영어라이브의 페이퍼북을 더 확실하고 재밌게 즐길 수
                      있는, 스마트한 펜! 스마트펜으로 책의 그림, 문장을 찍으면,
                      원어민의 정확한 영어 발음이 나와요. 녹음 기능으로
                      따라말하기 연습도 할 수 있어요!
                    </p>
                  </div>
                  <PricingCard
                    product={penProduct}
                    selected={penIncluded === true}
                    onClick={() => {
                      setPenIncluded(true);
                      setPenSelected(!penSelected);
                    }}
                  />
                  <PricingCard
                    selected={penIncluded === false}
                    onClick={() => {
                      setPenIncluded(false);
                      setPenSelected(!penSelected);
                    }}
                  />
                </>
              )}
            </>
          )}

          {selectedProduct && selectLevel === 'low' && (
            <div className="bg-secondary-1 w-full rounded-xl p-5 space-y-4">
              <div className="flex justify-between items-center">
                <span className="font-bold">{selectedProduct?.name}</span>
                <span>
                  {selectedProduct?.originalPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </span>
              </div>
              {bookIncluded && (
                <div className="flex justify-between items-center">
                  <span className="font-bold">{bookProduct?.name}</span>
                  <span>
                    {/* {nextLevel?.name === 'Level 3' ||
                    nextLevel?.name === 'Level 4' ? (
                      '무료'
                    ) : (
                      <> */}
                    {bookProduct?.finalPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    원
                    {/* </>
                    )} */}
                  </span>
                </div>
              )}
              {penIncluded && (
                <div className="flex justify-between items-center">
                  <span className="font-bold">{penProduct?.name}</span>
                  <span>
                    {freePen ? (
                      '무료'
                    ) : (
                      <>
                        {penProduct?.finalPrice
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        원
                      </>
                    )}
                  </span>
                </div>
              )}
              <div className="border-b bg-gray-50 mt-5" />
              <div className="flex justify-between items-center">
                <span className="text-gray-600 text-13 font-extrabold">
                  총 결제 금액
                </span>
                <div className="space-x-2">
                  <span className="text-primary-1 font-bold text-15">
                    {selectedProduct && freePen && bookIncluded && bookProduct
                      ? `${Math.round(
                          (1 -
                            (selectedProduct?.finalPrice +
                              bookProduct?.finalPrice) /
                              (selectedProduct?.originalPrice +
                                bookProduct?.originalPrice)) *
                            100
                        ).toString()}`
                      : selectedProduct && freePen
                      ? `${Math.round(
                          (1 -
                            selectedProduct?.finalPrice /
                              selectedProduct?.originalPrice) *
                            100
                        ).toString()}`
                      : // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4') &&
                      //   penIncluded &&
                      //   penProduct
                      // ? `${Math.round(
                      //     (1 -
                      //       (selectedProduct?.finalPrice +
                      //         penProduct?.finalPrice) /
                      //         (selectedProduct?.originalPrice +
                      //           penProduct?.originalPrice)) *
                      //       100
                      //   ).toString()}`
                      // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4')
                      // ? `${Math.round(
                      //     (1 -
                      //       selectedProduct?.finalPrice /
                      //         selectedProduct?.originalPrice) *
                      //       100
                      //   ).toString()}`
                      selectedProduct &&
                        bookIncluded &&
                        penIncluded &&
                        bookProduct &&
                        penProduct
                      ? `${Math.round(
                          (1 -
                            (selectedProduct?.finalPrice +
                              bookProduct?.finalPrice +
                              penProduct?.finalPrice) /
                              (selectedProduct?.originalPrice +
                                bookProduct?.originalPrice +
                                penProduct?.originalPrice)) *
                            100
                        ).toString()}`
                      : selectedProduct && bookIncluded && bookProduct
                      ? `${Math.round(
                          (1 -
                            (selectedProduct?.finalPrice +
                              bookProduct?.finalPrice) /
                              (selectedProduct?.originalPrice +
                                bookProduct?.originalPrice)) *
                            100
                        ).toString()}`
                      : `${Math.round(
                          (1 -
                            selectedProduct?.finalPrice /
                              selectedProduct?.originalPrice) *
                            100
                        ).toString()}`}
                    % 할인
                  </span>
                  <span className="text-gray-700 font-extrabold text-20">
                    {selectedProduct &&
                    selectedProduct &&
                    bookIncluded &&
                    bookProduct &&
                    freePen
                      ? `${(
                          selectedProduct?.finalPrice + bookProduct?.finalPrice
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      : selectedProduct && selectedProduct && freePen
                      ? `${(selectedProduct?.finalPrice)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      : // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4') &&
                      //   penIncluded &&
                      //   penProduct
                      // ? `${(selectedProduct?.finalPrice + penProduct?.finalPrice)
                      //     .toString()
                      //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4')
                      // ? `${(selectedProduct?.finalPrice)
                      //     .toString()
                      //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      selectedProduct &&
                        bookIncluded &&
                        penIncluded &&
                        bookProduct &&
                        penProduct
                      ? `${(
                          selectedProduct?.finalPrice +
                          bookProduct?.finalPrice +
                          penProduct?.finalPrice
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      : selectedProduct && bookIncluded && bookProduct
                      ? `${(
                          selectedProduct?.finalPrice + bookProduct?.finalPrice
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      : `${selectedProduct?.finalPrice
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`}
                  </span>
                </div>
              </div>
              <div className="flex space-x-3 justify-end">
                <span className="text-gray-400 text-13 line-through">
                  정가{' '}
                  {
                    //   selectedProduct &&
                    // (nextLevel?.name === 'Level 3' ||
                    //   nextLevel?.name === 'Level 4') &&
                    // freePen
                    //   ? selectedProduct?.originalPrice
                    //   : selectedProduct &&
                    //     (nextLevel?.name === 'Level 3' ||
                    //       nextLevel?.name === 'Level 4') &&
                    //     penIncluded &&
                    //     penProduct
                    //   ? selectedProduct?.originalPrice + penProduct?.originalPrice
                    //   : selectedProduct &&
                    //     (nextLevel?.name === 'Level 3' ||
                    //       nextLevel?.name === 'Level 4')
                    //   ? selectedProduct?.originalPrice
                    //   :
                    (selectedProduct &&
                    bookIncluded &&
                    penIncluded &&
                    bookProduct &&
                    penProduct
                      ? selectedProduct?.originalPrice +
                        bookProduct?.originalPrice +
                        penProduct?.originalPrice
                      : selectedProduct && bookIncluded && bookProduct
                      ? selectedProduct?.originalPrice +
                        bookProduct?.originalPrice
                      : selectedProduct?.originalPrice
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  원
                </span>
                <div className="flex flex-col">
                  <span className="font-extrabold text-13">
                    (월{' '}
                    {
                      // selectedProduct &&
                      // (nextLevel?.name === 'Level 3' ||
                      //   nextLevel?.name === 'Level 4') &&
                      // freePen
                      //   ? `${Math.floor(selectedProduct?.finalPrice / installment)
                      //       .toFixed(0)
                      //       .toString()
                      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      //   : selectedProduct &&
                      //     (nextLevel?.name === 'Level 3' ||
                      //       nextLevel?.name === 'Level 4') &&
                      //     penIncluded &&
                      //     penProduct
                      //   ? `${(
                      //       Math.floor(
                      //         selectedProduct?.finalPrice + penProduct?.finalPrice
                      //       ) / installment
                      //     )
                      //       .toFixed(0)
                      //       .toString()
                      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      // : selectedProduct &&
                      // (nextLevel?.name === 'Level 3' ||
                      //   nextLevel?.name === 'Level 4')
                      //   ? `${Math.floor(selectedProduct?.finalPrice / installment)
                      //       .toFixed(0)
                      //       .toString()
                      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      //   :
                      selectedProduct &&
                      bookIncluded &&
                      penIncluded &&
                      bookProduct &&
                      penProduct
                        ? `${(
                            Math.floor(
                              selectedProduct?.finalPrice +
                                bookProduct?.finalPrice +
                                penProduct?.finalPrice
                            ) / installment
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                        : selectedProduct && bookIncluded && bookProduct
                        ? `${Math.floor(
                            (selectedProduct?.finalPrice +
                              bookProduct?.finalPrice) /
                              installment
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                        : `${Math.floor(
                            selectedProduct?.finalPrice / installment
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                    }
                    )
                  </span>
                  <span className="text-11 text-right">{`${installment}개월 할부 시`}</span>
                </div>
              </div>
            </div>
          )}

          {selectedProduct && selectLevel === 'height' && (
            <div className="bg-secondary-1 w-full rounded-xl p-5 space-y-4">
              <div className="flex justify-between items-center">
                <span className="font-bold">{selectedProduct?.name}</span>
                <span>
                  {selectedProduct?.originalPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </span>
              </div>
              {bookIncluded && (
                <div className="flex justify-between items-center">
                  <span className="font-bold">{bookProduct?.name}</span>
                  <span>
                    {/* {nextLevel?.name === 'Level 3' ||
                    nextLevel?.name === 'Level 4' ||
                    (!nextLevel && selectLevel === 'height') ? (
                      '무료'
                    ) : (
                      <> */}
                    {bookProduct?.finalPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    원
                    {/* </>
                    )} */}
                  </span>
                </div>
              )}
              {penIncluded && (
                <div className="flex justify-between items-center">
                  <span className="font-bold">{penProduct?.name}</span>
                  <span>
                    {freePen ? (
                      '무료'
                    ) : (
                      <>
                        {penProduct?.finalPrice
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        원
                      </>
                    )}
                  </span>
                </div>
              )}
              <div className="border-b bg-gray-50 mt-5" />
              <div className="flex justify-between items-center">
                <span className="text-gray-600 text-13 font-extrabold">
                  총 결제 금액
                </span>
                <div className="space-x-2">
                  <span className="text-primary-1 font-bold text-15">
                    {selectedProduct &&
                    // nextLevel?.name === 'Level 3' ||
                    // nextLevel?.name === 'Level 4' ||
                    !nextLevel &&
                    freePen
                      ? `${Math.round(
                          (1 -
                            selectedProduct?.finalPrice /
                              selectedProduct?.originalPrice) *
                            100
                        ).toString()}`
                      : // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4') &&
                      //   penIncluded &&
                      //   penProduct
                      // ? `${Math.round(
                      //     (1 -
                      //       (selectedProduct?.finalPrice +
                      //         penProduct?.finalPrice) /
                      //         (selectedProduct?.originalPrice +
                      //           penProduct?.originalPrice)) *
                      //       100
                      //   ).toString()}`
                      selectedProduct && penIncluded && penProduct
                      ? `${Math.round(
                          (1 -
                            (selectedProduct?.finalPrice +
                              penProduct?.finalPrice) /
                              (selectedProduct?.originalPrice +
                                penProduct?.originalPrice)) *
                            100
                        ).toString()}`
                      : selectedProduct
                      ? `${Math.round(
                          (1 -
                            selectedProduct?.finalPrice /
                              selectedProduct?.originalPrice) *
                            100
                        ).toString()}`
                      : ''}
                    % 할인
                  </span>
                  <span className="text-gray-700 font-extrabold text-20">
                    {selectedProduct &&
                    // nextLevel?.name === 'Level 3' ||
                    // nextLevel?.name === 'Level 4' ||
                    !nextLevel &&
                    freePen
                      ? `${(selectedProduct?.finalPrice)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      : // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4') &&
                      //   penIncluded &&
                      //   penProduct
                      // ? `${(selectedProduct?.finalPrice + penProduct?.finalPrice)
                      //     .toString()
                      //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      selectedProduct && penIncluded && penProduct
                      ? `${(
                          selectedProduct?.finalPrice + penProduct?.finalPrice
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      : selectedProduct
                      ? `${(selectedProduct?.finalPrice)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      : ''}
                  </span>
                </div>
              </div>
              <div className="flex space-x-3 justify-end">
                <span className="text-gray-400 text-13 line-through">
                  정가{' '}
                  {
                    //   selectedProduct &&
                    // (nextLevel?.name === 'Level 3' ||
                    //   nextLevel?.name === 'Level 4') &&
                    // freePen
                    //   ? selectedProduct?.originalPrice
                    //   :
                    //   selectedProduct &&
                    //     (nextLevel?.name === 'Level 3' ||
                    //       nextLevel?.name === 'Level 4') &&
                    //     penIncluded &&
                    //     penProduct
                    //   ? selectedProduct?.originalPrice + penProduct?.originalPrice
                    //   : selectedProduct &&
                    //     (nextLevel?.name === 'Level 3' ||
                    //       nextLevel?.name === 'Level 4')
                    //   ? selectedProduct?.originalPrice
                    //   :
                    (selectedProduct &&
                    bookIncluded &&
                    penIncluded &&
                    bookProduct &&
                    penProduct
                      ? selectedProduct?.originalPrice +
                        bookProduct?.originalPrice +
                        penProduct?.originalPrice
                      : selectedProduct && bookIncluded && bookProduct
                      ? selectedProduct?.originalPrice +
                        bookProduct?.originalPrice
                      : selectedProduct?.originalPrice
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  원
                </span>
                <div className="flex flex-col">
                  <span className="font-extrabold text-13">
                    (월{' '}
                    {
                      // selectedProduct &&
                      // (nextLevel?.name === 'Level 3' ||
                      //   nextLevel?.name === 'Level 4') &&
                      // freePen
                      //   ? `${Math.floor(selectedProduct?.finalPrice / installment)
                      //       .toFixed(0)
                      //       .toString()
                      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      //   : selectedProduct &&
                      //     (nextLevel?.name === 'Level 3' ||
                      //       nextLevel?.name === 'Level 4') &&
                      //     penIncluded &&
                      //     penProduct
                      //   ? `${(
                      //       Math.floor(
                      //         selectedProduct?.finalPrice + penProduct?.finalPrice
                      //       ) / installment
                      //     )
                      //       .toFixed(0)
                      //       .toString()
                      //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      //   :
                      // selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4')
                      // ? `${Math.floor(selectedProduct?.finalPrice / installment)
                      //     .toFixed(0)
                      //     .toString()
                      //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                      // :
                      selectedProduct &&
                      bookIncluded &&
                      penIncluded &&
                      bookProduct &&
                      penProduct
                        ? `${(
                            Math.floor(
                              selectedProduct?.finalPrice +
                                bookProduct?.finalPrice +
                                penProduct?.finalPrice
                            ) / installment
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                        : selectedProduct && bookIncluded && bookProduct
                        ? `${Math.floor(
                            (selectedProduct?.finalPrice +
                              bookProduct?.finalPrice) /
                              installment
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                        : `${Math.floor(
                            selectedProduct?.finalPrice / installment
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                    }
                    )
                  </span>
                  <span className="text-11 text-right">{`${installment}개월 할부 시`}</span>
                </div>
              </div>
            </div>
          )}

          <div className="pb-6">
            <div className="border-t pb-6 border-gray-50" />
            <Button
              text="수강신청하기"
              className="filled-primary-1 w-full"
              disabled={
                !selectedProduct ||
                !bookSelected ||
                (bookIncluded && !penSelected)
              }
              onClick={() => {
                if (!authenticated) {
                  return requireLoginPopup();
                }
                if (!student) {
                  return addStudentPopup();
                }
                if (!testResult || !student.isTested) {
                  return requireLevelTestPopup(student?.id);
                }
                const productIds = [selectedProduct?.id];

                push('/purchase', {
                  studentId: studentState.get()?.id,
                  productIds,
                  bookIncluded: bookIncluded,
                  penIncluded: penIncluded,
                  productAmount: selectedProduct?.amount,
                  productExpiredAt: selectedProduct?.expiredAt,
                  totalAmount:
                    selectedProduct &&
                    selectLevel === 'low' &&
                    bookIncluded &&
                    bookProduct &&
                    freePen
                      ? selectedProduct?.finalPrice + bookProduct?.finalPrice
                      : selectedProduct && selectLevel === 'low' && freePen
                      ? selectedProduct?.finalPrice
                      : // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4') &&
                      //   freePen
                      // ? selectedProduct?.finalPrice
                      // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4') &&
                      //   penIncluded &&
                      //   penProduct
                      // ? selectedProduct?.finalPrice + penProduct?.finalPrice
                      // : selectedProduct &&
                      //   (nextLevel?.name === 'Level 3' ||
                      //     nextLevel?.name === 'Level 4')
                      // ? selectedProduct?.finalPrice
                      selectedProduct &&
                        bookIncluded &&
                        penIncluded &&
                        bookProduct &&
                        penProduct
                      ? selectedProduct?.finalPrice +
                        bookProduct?.finalPrice +
                        penProduct?.finalPrice
                      : selectedProduct && bookIncluded && bookProduct
                      ? selectedProduct?.finalPrice + bookProduct?.finalPrice
                      : selectedProduct?.finalPrice,
                });
              }}
            />
          </div>
        </Section>
      </div>
    </div>
  );
};
