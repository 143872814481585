import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { Button } from '../components/Button';
import { H5 } from '../components/H5';
import { usePopup } from '../components/PopUp';
import { PricingCard } from '../components/PricingCard';
import { Section } from '../components/Section';
import { useAuth } from '../hooks';
import { api } from '../plugins/axios';
import { fetcher } from '../plugins/react-query';
import { popupState, studentState, testResultState } from '../plugins/ridge';
import {
  ClassType,
  Course,
  LiveClass,
  Product,
  ProductLiveType,
  User,
} from '../types';
import HeroImg from '../assets/img/pricing-speaking-hero.png';
import { LevelTab } from '../components/LevelTab';

export const PricingSpecialPage = () => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const { requireLoginPopup, addStudentPopup, requireLevelTestPopup } =
    usePopup();
  const testResult = testResultState.useValue();
  const student = studentState.useValue();

  const [selectLevel, setSelectLevel] = useState<ProductLiveType>(
    ProductLiveType.SPEAKING
  );

  const { data: products } = useQuery<Product[]>('/products/open', fetcher);
  const { data: remainingLiveClasses } = useQuery<LiveClass[]>(
    `/live-classes/remaining/special/${student?.id}/${selectLevel}`,
    fetcher
  );

  const specialProducts = products?.filter((p) => {
    if (!p.classType) return false;

    return p.classType === ClassType.SPECIAL;
  });

  const getProductByType = (type: ProductLiveType) => {
    setSpecialProductsByType(
      specialProducts?.filter((p) => p.liveType === type)
    );
  };

  const [isClicked, setIsClicked] = useState<number>(0);
  const [selectProduct, setSelectProduct] = useState<Product>();
  const [specialProductsByType, setSpecialProductsByType] = useState<
    Product[] | undefined
  >();

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
  });

  const { data: myCourses } = useQuery<Course[]>(`/courses/my`, fetcher, {
    enabled: !!student,
  });

  const trialPackage = myCourses?.find(
    (course) => course.isTrial && course.initialAmount !== 1
  );
  const trialCourse = myCourses?.find(
    (course) =>
      course.isTrial &&
      course.initialAmount === 1 &&
      course.studentId === student?.id
  );
  const course = myCourses?.find((course) => !course.isTrial);

  const isDisabled = !!!selectProduct;

  useEffect(() => {
    getProductByType(ProductLiveType.SPEAKING);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full">
      <div className="Hero-Img-pa">
        <img src={HeroImg} alt="pricing page" />
      </div>
      <div className="pp_page">
        {/* <Section className="max-w-sm mx-auto w-full space-y-6 px-4"> */}
        <Section className="max-w-sm mx-auto space-y-6 px-4">
          <H5 className="pr-tit-pa">특강수업 신청</H5>
          <div className="flex space-x-4 border-b border-gray-50">
            {[{ id: 1, title: '스피킹', value: ProductLiveType.SPEAKING }].map(
              (l) => (
                <LevelTab
                  key={l.id}
                  title={l.title}
                  selected={selectLevel === l.value}
                  onClick={() => {
                    setSelectLevel(l.value);
                    setSelectProduct(undefined);
                  }}
                />
              )
            )}
          </div>
          {specialProductsByType?.map((product) => (
            <PricingCard
              product={product}
              selected={selectProduct?.id === product.id}
              onClick={() => setSelectProduct(product)}
            />
          ))}

          <div>
            <div className="border-t pb-6 border-gray-50" />
            <Button
              text="수강신청하기"
              className="filled-primary-1 w-full"
              disabled={
                !selectProduct
                  ? true
                  : !authenticated
                  ? false
                  : selectProduct.amount >
                    (!remainingLiveClasses ? 0 : remainingLiveClasses.length)
              }
              onClick={async () => {
                // 수강권 확인
                if (!selectProduct) return;

                // 로그인 확인
                if (!authenticated) {
                  return requireLoginPopup();
                }

                // 자녀 여부 확인
                if (!student) {
                  return addStudentPopup();
                }

                // 레벨테스트 확인
                // if (!testResult || !student.isTested) {
                //   return requireLevelTestPopup(student?.id);
                // }

                // 수강권 구매 여부
                // maxLive 여기서 확인
                // if (
                //   selectProduct &&
                //   selectProduct?.finalPrice > 0 &&
                //   (trialPackage || me?.purchaseCompleted)
                // ) {
                //   alert('이미 체험팩을 구매하셨습니다.');
                //   return;
                // }

                // if (
                //   selectProduct &&
                //   selectProduct?.finalPrice === 0 &&
                //   trialCourse
                // ) {
                //   alert('이미 체험 수업을 진행했습니다.');
                //   return;
                // }

                // 다중 클릭 확인
                setIsClicked(isClicked + 1);

                try {
                  if (isClicked === 0) {
                    if (selectProduct.finalPrice > 0) {
                      // 특강 패키지 결제
                      push('/special/purchase', {
                        studentId: studentState.get()?.id,
                        productIds: [selectProduct.id],
                        productAmount: selectProduct?.amount,
                        totalAmount: selectProduct?.finalPrice,
                        productExpiredAt: selectProduct?.expiredAt,
                      });
                    } else {
                      await api.post('/payments/temp', {
                        studentId: studentState.get()?.id,
                        productIds: [selectProduct.id],
                      });
                      setIsClicked(0);
                      push('/purchase/success?orderNumber=isTrail');
                    }
                  }
                } catch (e) {
                  setIsClicked(0);
                }
              }}
            />
          </div>
        </Section>
      </div>
    </div>
  );
};
