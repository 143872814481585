import React, { useEffect, useRef, useState } from 'react';
import mainTopImg from '../../assets/img/main-slide03-pc.jpg';
import mobilemainTopImg from '../../assets/img/main-slide03-m.jpg';
import mainBottomImg from '../../assets/img/main-bottom-img.png';
import mobilemainBottomImg from '../../assets/img/mobile-main-bottom-img.png';
import mainTopImg01 from '../../assets/img/main-slide18-pc.png';
import mobilemainTopImg01 from '../../assets/img/main-slide18-m.png';
import mainTopImg02 from '../../assets/img/main-slide21-pc.png';
import mobilemainTopImg02 from '../../assets/img/main-slide21-m.png';
import mainTopImg03 from '../../assets/img/main-slide20-pc.png';
import mobilemainTopImg03 from '../../assets/img/main-slide20-m.png';
import mainTopImg04 from '../../assets/img/main-slide14-pc.png';
import mobilemainTopImg04 from '../../assets/img/main-slide14-m.png';
import { HomeTab } from '../../components/HomeTab';
import { TeacherIntroduction } from './TeacherIntroduction';
import { LessonIntroduction } from './LessonIntroduction';
import { ReactComponent as BottomLogo } from '../../assets/icons/icon-bottom-logo.svg';
import { ReviewSection } from './ReviewSection';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../layout.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { HOME_TABS } from '../../constant';
import { elementScrollIntoView } from 'seamless-scroll-polyfill/lib/scrollIntoView';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { EventModal } from '../../components/Modal/eventModal';
import { useCookies } from 'react-cookie';
import moment from 'moment';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false); //팝업 노출시 : true, 팝업 숨김시 : false
  const teacherIntroRef = useRef<HTMLDivElement>(null);
  const lessonIntroRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState(HOME_TABS.TEACHER);
  const [hasCookie, setHasCookie] = useState(true); // 쿠키 존재 여부
  const [appCookies, setAppCookies] = useCookies(); // 쿠키 설정

  //팝업 스크롤 유무
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'unset';
  //   }
  // }, [isOpen]);

  const _scrollRef = (tab: HOME_TABS) => {
    if (tab === HOME_TABS.TEACHER && teacherIntroRef.current) {
      elementScrollIntoView(teacherIntroRef.current, {
        behavior: 'smooth',
        block: 'center',
      });
    }
    if (tab === HOME_TABS.LESSON && lessonIntroRef.current) {
      elementScrollIntoView(lessonIntroRef.current, {
        behavior: 'smooth',
        block: 'center',
      });
    }
    if (tab === HOME_TABS.REVIEW && reviewRef.current) {
      elementScrollIntoView(reviewRef.current, {
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    const options = {
      threshold: 0,
    };

    const handleIntersection = ([entry]: any, observer: any) => {
      if (!entry.isIntersecting) {
        return;
      }
      setTab(entry.target.id);
    };

    const io = new IntersectionObserver(handleIntersection, options);
    teacherIntroRef.current && io.observe(teacherIntroRef.current);
    lessonIntroRef.current && io.observe(lessonIntroRef.current);
    reviewRef.current && io.observe(reviewRef.current);

    return () => io && io.disconnect();
  }, []);

  // 하루동안 닫기
  const closeModalUntilExpires = () => {
    if (!appCookies) return;
    const expiresDateTime = moment().endOf('day').toDate();
    setAppCookies('MODAL_EXPIRES', true, {
      path: '/',
      expires: expiresDateTime,
    });
    setIsOpen(false);
  };

  // 쿠기 존재 확인
  useEffect(() => {
    if (appCookies['MODAL_EXPIRES']) return;
    setHasCookie(false);
  }, [appCookies]);

  return (
    <>
      {isOpen && !hasCookie && (
        <EventModal
          onClose={() => setIsOpen(false)}
          secondaryOnClose={() => closeModalUntilExpires()}
          buttonTitle="닫 기"
          secondaryButtonTitle="하루동안 닫기"
        />
      )}

      <div className="font-nanum">
        <Swiper
          className="main-slide"
          slidesPerView={1}
          pagination={{ clickable: true }}
          loop={false}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          <SwiperSlide>
            <div
              style={{
                backgroundImage: `url('${
                  isMobile ? mobilemainTopImg : mainTopImg
                }')`,
              }}
              className="z-0 w-full bg-center bg-cover bg-no-repeat md:h-782 main-img"
            >
              <div className="z-30 text-left max-w-container md:h-782 h-650 main-img-txt">
                <h1 className="md:text-48 text-30 font-light">
                  화상으로 만나니까
                  <br />
                  <span className="md:text-56 text-30 font-normal key-txt">
                    더 기본에 충실하게!
                  </span>
                </h1>
                <p className="md:text-20 text-15 pt-5 font-medium">
                  260만 회원이 검증한 튼튼영어 프로그램을 <br />
                  이젠 화상으로 만나보세요.
                </p>
              </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div
              style={{
                backgroundImage: `url('${
                  isMobile ? mobilemainTopImg02 : mainTopImg02
                }')`,
              }}
              className="z-0 w-full bg-center bg-cover bg-no-repeat md:h-782 h-650 main-img"
            >
              <a href="https://tuntunlive.com/pricing/trial" className="main-href"></a>
            </div>
          </SwiperSlide> */}
          {/*<SwiperSlide>
            <div
              style={{
                backgroundImage: `url('${
                  isMobile ? mobilemainTopImg01 : mainTopImg01
                }')`,
              }}
              className="z-0 w-full bg-center bg-cover bg-no-repeat md:h-782 h-650 main-img"
            >
              <a
                href="https://tuntunlive.com/events/9"
                className="main-href"
              ></a>
            </div>
            </SwiperSlide>*/}
          {/* <SwiperSlide>
            <div
              style={{
                backgroundImage: `url('${
                  isMobile ? mobilemainTopImg03 : mainTopImg03
                }')`,
              }}
              className="z-0 w-full bg-center bg-cover bg-no-repeat md:h-782 h-650 main-img"
            >
              <a
                href="https://smartstore.naver.com/tuntunenglish/products/8707915936"
                target="_blank"
                className="main-href"
              ></a>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <div
              style={{
                backgroundImage: `url('${
                  isMobile ? mobilemainTopImg04 : mainTopImg04
                }')`,
              }}
              className="z-0 w-full bg-center bg-cover bg-no-repeat md:h-782 h-650 main-img"
            >
              <a
                href="https://tuntunlive.com/pricing/special"
                className="main-href"
              ></a>
            </div>
          </SwiperSlide> */}
        </Swiper>
        <div className="mx-auto border-b border-gray-100 sticky md:top-0 top-20 bg-white z-10">
          <div className="px-2 md:w-1/2 grid grid-cols-3 mx-auto gap-x-8">
            {Object.values(HOME_TABS).map((t) => (
              <HomeTab
                key={t}
                selected={t === tab}
                onClick={() => {
                  _scrollRef(t);
                }}
                title={t}
              />
            ))}
          </div>
        </div>

        <div ref={teacherIntroRef} id={HOME_TABS.TEACHER} />
        {/* 라이브쌤 소개 */}
        <TeacherIntroduction />

        <div ref={lessonIntroRef} id={HOME_TABS.LESSON} />
        {/* 라이브학습 소개 */}
        <LessonIntroduction />

        <div ref={reviewRef} id={HOME_TABS.REVIEW} />
        {/* 라이브 후기 */}
        <ReviewSection />

        <ScrollAnimation
          animateIn="animate__fadeIn"
          animateOnce={true}
          className=""
        >
          <div
            style={{
              backgroundImage: `url('${
                isMobile ? mobilemainBottomImg : mainBottomImg
              }')`,
              height: 782,
            }}
            className="w-full bg-center bg-cover bg-no-repeat"
          >
            <div className="grid place-content-center bg-black bg-opacity-30 w-full h-full md:space-y-12 space-y-6">
              <BottomLogo className="mx-auto md:w-full w-36" />
              <h1 className="lg:text-48 md:text-3xl text-2xl text-white text-center font-nanum leading-relaxed	">
                화상으로 만나니까{` `}
                <br className="lg:hidden block" />더 기본에 충실하게!
              </h1>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
};
