import React, { FC } from 'react';
import { useQuery } from 'react-query';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { H6 } from '../../components/H6';
import { ChevronRightIcon } from '../../components/icons';
import { Section } from '../../components/Section';
import { useAuth } from '../../hooks';
import { apiUrl } from '../../plugins/axios';
import ChannelService from '../../plugins/ChannelService';
import { fetcher } from '../../plugins/react-query';
import { User } from '../../types';
import { ManagePage } from './ManagePage';
import { MyPage } from './MyPage';
import { NoticeDetailsPage } from './NoticeDetailsPage';
import { NoticesPage } from './NoticesPage';
import { PaymentsPage } from './PaymentsPage';
import { RewardsPage } from './RewardsPage';
import { StudentsPage } from './StudentsPage';

const sidebar = [
  { title: '자녀 학습현황', path: '/parent/students' },
  { title: '자녀 관리', path: '/parent/manage' },
  { title: '쿠폰 · 포인트 ', path: '/parent/rewards' },
  { title: '배송 · 결제 내역', path: '/parent/payments' },
  { title: '내 정보 수정', path: '/parent/mypage' },
  { title: '공지사항', path: '/parent/notices' },
];

export const ParentPage = () => {
  const { authenticated } = useAuth();
  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
  });

  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CHANNEL_IO_KEY,
    mobileMessengerMode: 'iframe',
    zIndex: 100,
    customLauncherSelector: '#cannelIo-button2',
    profile: {
      name: me ? me.name : '비회원',
      mobileNumber: me ? me.phoneNumber : '-',
      email: me ? me.email : '-',
      avatarUrl: me?.avatar ? apiUrl(me.avatar) : '-',
    },
  });

  return (
    <div style={{ backgroundColor: '#F9FAFB' }} className="min-h-screen">
      <div className="flex md:space-x-6 max-w-6xl mx-auto md:px-0 pt-10 pb-20">
        <Section className="hidden md:block bg-white rounded-md w-1/5 shadow-3xl h-full">
          <H6 className="p-5">부모님</H6>
          {sidebar.map((item) => (
            <SidebarItem key={item.title} title={item.title} path={item.path} />
          ))}
          <div
            id="cannelIo-button2"
            className={`flex justify-between cursor-pointer items-center w-full p-5`}
          >
            <p className="text-gray-600">문의하기</p>
            <ChevronRightIcon className="wh-5 text-gray-600 stroke-current" />
          </div>
        </Section>

        <div className="flex-1 px-5 md:px-0">
          <Switch>
            <Route path="/parent/students" component={StudentsPage} />
            <Route path="/parent/manage" component={ManagePage} />
            <Route path="/parent/rewards" component={RewardsPage} />
            <Route path="/parent/payments" component={PaymentsPage} />
            <Route path="/parent/mypage" component={MyPage} />
            <Route path="/parent/notices/:id" component={NoticeDetailsPage} />
            <Route path="/parent/notices" component={NoticesPage} />
            <Redirect from="/" to="/parent/students" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

interface SidebarItemProps {
  title: string;
  path: string;
}

export const SidebarItem: FC<SidebarItemProps> = ({ title, path }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const selected = pathname.startsWith(path);

  return (
    <>
      <button
        onClick={() => push(path)}
        className={`${
          selected ? 'bg-gray-50' : ''
        } flex justify-between items-center w-full p-5`}
      >
        <p className="text-gray-600">{title}</p>
        <ChevronRightIcon className="wh-5 text-gray-600 stroke-current" />
      </button>
    </>
  );
};
