import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { useAuth } from '../hooks';
import { apiUrl } from '../plugins/axios';
import ChannelService from '../plugins/ChannelService';
import { fetcher } from '../plugins/react-query';
import { studentState, testResultState } from '../plugins/ridge';
import { Role, Student, User } from '../types';
import { Avatar } from './Avatar';
import { Button } from './Button';
import { Dropdown } from './Dropdown';
import { MenuIcon, XIcon } from './icons';
import { Menu } from './Menu';
import { usePopup } from './PopUp';

export const TopNavBar = () => {
  const { push } = useHistory();
  const { authenticated, logout } = useAuth();
  const { requireLoginPopup, requireLevelTestPopup, addStudentPopup } =
    usePopup();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [childDropdown, setChildDropdown] = useState(false);
  const student = studentState.useValue();
  const testResult = testResultState.useValue();

  const [toggle, setToggle] = useState(false);

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
  });
  const { data: students } = useQuery<Student[]>('/students', fetcher, {
    enabled: authenticated,
  });
  const role = me?.role ?? Role.USER;

  const handleLearnClick = () => {
    if (!authenticated) return requireLoginPopup();
    if (students?.length === 0) return addStudentPopup();
    push('/learn');
    setShowSideMenu(false);
  };

  const handleLibraryClick = () => {
    if (!authenticated) return requireLoginPopup();
    if (students?.length === 0) return addStudentPopup();
    push('/library');
    setShowSideMenu(false);
  };

  const handleLevelTestClick = () => {
    const student = studentState.get();
    if (!authenticated) return requireLoginPopup();
    if (students?.length === 0) return addStudentPopup();
    if (!testResult || !student?.isTested)
      return requireLevelTestPopup(student?.id);
    push('/level-test/result');
    setShowSideMenu(false);
  };

  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CHANNEL_IO_KEY,
    mobileMessengerMode: 'iframe',
    zIndex: 100,
    hideChannelButtonOnBoot: me?.role === Role.ADMIN && true,
    customLauncherSelector: '#cannelIo-button1',
    profile: {
      name: me ? me.name : '비회원',
      mobileNumber: me ? me.phoneNumber : '-',
      email: me ? me.email : '-',
      avatarUrl: me?.avatar ? apiUrl(me.avatar) : '-',
    },
  });

  return (
    <>
      {/* Mobile TopNavBar */}
      <div className="sticky top-0 bg-white md:hidden z-40">
        <div className="flex justify-between p-5">
          <div className="flex">
            <div
              className={`absolute w-full h-screen -mx-5 bg-white px-5 z-50 ${
                showSideMenu
                  ? 'ease-in-out duration-500 opacity-100 translate-x-0 '
                  : 'ease-in duration-250 opacity-0 pointer-events-none -translate-x-5 '
              }`}
            >
              <div className="grid place-content-end">
                <button onClick={() => setShowSideMenu(false)}>
                  <XIcon />
                </button>
              </div>

              {!authenticated && (
                <div className="flex flex-col space-y-4">
                  <span className="text-17 font-nanum">튼튼영어</span>
                  <TopNavBarLink
                    to="/curriculum"
                    onClick={() => {
                      // push('/curriculum');
                      // setShowSideMenu(false);
                      setToggle(!toggle);
                    }}
                  >
                    <div className={toggle ? 'nav-active' : 'none'}>
                      커리큘럼
                    </div>
                    <ul
                      style={{ display: toggle ? 'block' : 'none' }}
                      className="nav-depth"
                    >
                      <li>
                        <TopNavBarLink
                          to="/curriculum"
                          onClick={() => {
                            push('/curriculum');
                            setShowSideMenu(false);
                          }}
                        >
                          정규
                        </TopNavBarLink>
                      </li>
                      <li>
                        <TopNavBarLink
                          to="/speaking"
                          onClick={() => {
                            push('/speaking');
                            setShowSideMenu(false);
                          }}
                        >
                          특강
                        </TopNavBarLink>
                      </li>
                    </ul>
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/live-teacher"
                    onClick={() => {
                      push('/live-teacher');
                      setShowSideMenu(false);
                    }}
                  >
                    라이브 선생님
                  </TopNavBarLink>
                  {/* <TopNavBarLink
                    to="/pricing"
                    onClick={() => {
                      push('/pricing');
                      setShowSideMenu(false);
                    }}
                  >
                    수강신청
                  </TopNavBarLink> */}
                  <TopNavBarLink
                    to="/events"
                    onClick={() => {
                      push('/events');
                      setShowSideMenu(false);
                    }}
                  >
                    이벤트
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/events"
                    onClick={() => {
                      push('/faq');
                      setShowSideMenu(false);
                    }}
                  >
                    FAQ
                  </TopNavBarLink>
                  {/* <TopNavBarLink
                    to="/signup"
                    onClick={() => {
                      push('/signup');
                      setShowSideMenu(false);
                    }}
                  >
                    <a className="free_log_m">회원가입(무료체험)</a>
                  </TopNavBarLink> */}
                </div>
              )}
              {authenticated && role === Role.USER && (
                <div className="flex flex-col space-y-4">
                  <span className="text-17 font-nanum">자녀</span>
                  <TopNavBarLink to="/learn" onClick={handleLearnClick}>
                    나의 학습
                  </TopNavBarLink>
                  <TopNavBarLink to="/library" onClick={handleLibraryClick}>
                    나의 도서관
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/level-test/result"
                    onClick={handleLevelTestClick}
                  >
                    레벨테스트
                  </TopNavBarLink>
                  {/* <TopNavBarLink
                    to="/pricing/regular"
                    onClick={() => {
                      push('/pricing/regular');
                      setShowSideMenu(false);
                    }}
                  >
                    수강 신청
                  </TopNavBarLink> */}
                  <div className="border border-gray-50 my-4" />
                  <span className="text-17 font-nanum">부모님</span>
                  <TopNavBarLink
                    to="/parent/students"
                    onClick={() => {
                      push('/parent/students');
                      setShowSideMenu(false);
                    }}
                  >
                    자녀 학습현황
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/parent/manage"
                    onClick={() => {
                      push('/parent/manage');
                      setShowSideMenu(false);
                    }}
                  >
                    자녀 관리
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/parent/rewards"
                    onClick={() => {
                      push('/parent/rewards');
                      setShowSideMenu(false);
                    }}
                  >
                    쿠폰 · 포인트
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/parent/payments"
                    onClick={() => {
                      push('/parent/payments');
                      setShowSideMenu(false);
                    }}
                  >
                    배송 · 결제 내역
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/parent/mypage"
                    onClick={() => {
                      push('/parent/mypage');
                      setShowSideMenu(false);
                    }}
                  >
                    내 정보 수정
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/parent/notices"
                    onClick={() => {
                      push('/parent/notices');
                      setShowSideMenu(false);
                    }}
                  >
                    공지사항
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/events"
                    onClick={() => {
                      push('/events');
                      setShowSideMenu(false);
                    }}
                  >
                    이벤트
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/events"
                    onClick={() => {
                      push('/faq');
                      setShowSideMenu(false);
                    }}
                  >
                    FAQ
                  </TopNavBarLink>
                  <div id="cannelIo-button1" className="cursor-pointer">
                    문의하기
                  </div>
                  <div className="border border-gray-50 my-4" />
                  <TopNavBarLink
                    onClick={() => {
                      localStorage.clear();
                      logout();
                    }}
                  >
                    로그아웃
                  </TopNavBarLink>
                </div>
              )}
              {authenticated && role === Role.TEACHER && (
                <div className="flex flex-col space-y-4">
                  <span className="text-17 font-nanum">선생님</span>
                  <TopNavBarLink
                    to="/teacher/schedule"
                    onClick={() => {
                      push('/teacher/schedule');
                      setShowSideMenu(false);
                    }}
                  >
                    수업 스케줄
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/teacher/students"
                    onClick={() => {
                      push('/teacher/students');
                      setShowSideMenu(false);
                    }}
                  >
                    나의 학생
                  </TopNavBarLink>
                  <TopNavBarLink
                    to="/teacher/prepare"
                    onClick={() => {
                      push('/teacher/prepare');
                      setShowSideMenu(false);
                    }}
                  >
                    수업 준비
                  </TopNavBarLink>
                  <div className="border border-gray-50 my-4" />
                  <TopNavBarLink
                    onClick={() => {
                      localStorage.clear();
                      logout();
                    }}
                  >
                    로그아웃
                  </TopNavBarLink>
                </div>
              )}
              {authenticated && role === Role.ADMIN && (
                <TopNavBarLink to="/admin">어드민</TopNavBarLink>
              )}
            </div>
            <button onClick={() => setShowSideMenu(true)}>
              <MenuIcon />
            </button>
            <TopNavBarLink to="/">
              <Logo className="pl-3 h-9" />
            </TopNavBarLink>
          </div>
          <div className="flex space-x-4 items-center">
            {!authenticated && (
              <Button
                to="/login"
                text="로그인"
                className="rounded-full filled-primary-1 px-4 h-10"
              />
            )}
            {authenticated && role === Role.USER && (
              <>
                <div
                  className={`fixed inset-0 ${
                    childDropdown
                      ? 'ease-in-out duration-500 opacity-100 translate-y-0 '
                      : 'ease-in duration-250 opacity-0 pointer-events-none -translate-y-5 '
                  }`}
                >
                  <div className="bg-white p-5">
                    <div className="flex justify-between pb-4">
                      <span className="font-nanum text-17">나의 자녀</span>
                      <button onClick={() => setChildDropdown(false)}>
                        <XIcon />
                      </button>
                    </div>

                    <div className="flex items-start">
                      {students?.map((student) => (
                        <div
                          key={student.id}
                          className="flex flex-col items-center space-y-1"
                          onClick={() => {
                            localStorage.setItem(
                              'studentId',
                              student.id.toString()
                            );
                            studentState.set(student);
                          }}
                        >
                          <Avatar
                            src={apiUrl(student.avatar)}
                            className="wh-8"
                          />
                          <span>{student.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    onClick={() => setChildDropdown(false)}
                    className="bg-littleblack h-full"
                  />
                </div>
                {student ? (
                  <div className="flex space-x-1 items-center">
                    <span>{student.name}</span>
                    <button
                      onClick={() => setChildDropdown(true)}
                      className="border-2 border-secondary-5 rounded-full wh-10"
                    >
                      <Avatar
                        src={apiUrl(student.avatar)}
                        className="wh-8 object-cover"
                      />
                    </button>
                  </div>
                ) : (
                  <TopNavBarLink
                    onClick={() => {
                      localStorage.clear();
                      logout();
                    }}
                  >
                    로그아웃
                  </TopNavBarLink>
                )}
                {/* <Button
                  to="/parent"
                  text="부모님"
                  className="rounded-full filled-gray-800 px-4 h-10"
                /> */}
              </>
            )}
            {authenticated && role === Role.TEACHER && me && (
              <div className="flex space-x-1 items-center">
                <span>{me.name}</span>
                <Dropdown>
                  <button className="border-2 border-secondary-5 rounded-full wh-10">
                    <Avatar src={apiUrl(me.avatar)} className="wh-8" />
                  </button>
                  <Dropdown.View className="right-0">
                    <Menu>
                      <Menu.Item text="마이 페이지" to="/teacher/mypage" />
                      <Menu.Item
                        text="로그아웃"
                        onClick={() => {
                          localStorage.clear();
                          logout();
                        }}
                      />
                    </Menu>
                  </Dropdown.View>
                </Dropdown>
              </div>
            )}
            {authenticated && role === Role.ADMIN && (
              <>
                <Button
                  text="로그아웃"
                  className="px-4 h-10 outlined-gray-600"
                  onClick={() => {
                    localStorage.clear();
                    logout();
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* Desktop TopNavBar */}
      <div className="border-gray-100 border-b">
        <div className="hidden md:flex max-w-container justify-between">
          <div className="flex items-center space-x-8">
            <TopNavBarLink to="/">
              <Logo />
            </TopNavBarLink>
            {!authenticated && (
              <>
                <TopNavBarLink to="#">
                  <a className="nav-menu-item">
                    커리큘럼
                    <ul className="nav-submenu">
                      <li
                        onClick={() => {
                          push('/curriculum');
                        }}
                        className="nav-submenu-item"
                      >
                        정규
                      </li>
                      <li
                        onClick={() => {
                          push('/speaking');
                        }}
                        className="nav-submenu-item"
                      >
                        특강
                      </li>
                    </ul>
                  </a>
                </TopNavBarLink>
                <TopNavBarLink to="/live-teacher">라이브 선생님</TopNavBarLink>
                {/* <TopNavBarLink to="/pricing/regular">수강신청</TopNavBarLink> */}
                <TopNavBarLink to="/events">이벤트</TopNavBarLink>
                <TopNavBarLink to="/faq">FAQ</TopNavBarLink>
              </>
            )}
            {authenticated && role === Role.USER && (
              <>
                <TopNavBarLink to="/learn" onClick={handleLearnClick}>
                  나의 학습
                </TopNavBarLink>
                <TopNavBarLink to="/library" onClick={handleLibraryClick}>
                  나의 도서관
                </TopNavBarLink>
                <TopNavBarLink
                  to="/level-test/result"
                  onClick={handleLevelTestClick}
                >
                  레벨테스트
                </TopNavBarLink>
                {/* <TopNavBarLink to="/pricing/regular">수강 신청</TopNavBarLink> */}
                <TopNavBarLink to="/parent/rewards">친구초대</TopNavBarLink>
                <TopNavBarLink to="/events">이벤트</TopNavBarLink>
                <TopNavBarLink to="/faq">FAQ</TopNavBarLink>
              </>
            )}
            {authenticated && role === Role.TEACHER && (
              <>
                <TopNavBarLink to="/teacher/schedule">
                  수업 스케줄
                </TopNavBarLink>
                <TopNavBarLink to="/teacher/students">나의 학생</TopNavBarLink>
                <TopNavBarLink to="/teacher/prepare">수업 준비</TopNavBarLink>
              </>
            )}
            {authenticated && role === Role.ADMIN && (
              <TopNavBarLink to="/admin">어드민</TopNavBarLink>
            )}
          </div>
          <div className="flex space-x-4 items-center">
            {/* {!authenticated && (
              <Button
                to="/signup"
                text="회원가입(무료체험)"
                className="rounded-full filled-primary-1 px-4 h-10 free_log"
              />
            )} */}
            {!authenticated && (
              <Button
                to="/login"
                text="로그인"
                className="rounded-full filled-primary-1 px-4 h-10"
              />
            )}
            {authenticated && role === Role.USER && (
              <>
                {student ? (
                  <div className="flex space-x-2 items-center">
                    <span>{student.name}</span>
                    <Dropdown>
                      <button className="border-2 border-secondary-5 rounded-full wh-10">
                        <Avatar
                          src={apiUrl(student.avatar)}
                          className="wh-8 object-cover"
                        />
                      </button>
                      <Dropdown.View className="right-0">
                        <Menu>
                          {students?.map((student) => (
                            <Menu.Item
                              key={student.id}
                              className="flex items-center"
                              onClick={() => {
                                localStorage.setItem(
                                  'studentId',
                                  student.id.toString()
                                );
                                studentState.set(student);
                              }}
                            >
                              <Avatar
                                src={apiUrl(student.avatar)}
                                className="wh-8 mr-2 object-cover"
                              />
                              {student.name}
                            </Menu.Item>
                          ))}
                          <Menu.Item
                            text="로그아웃"
                            className="text-center"
                            onClick={() => {
                              localStorage.clear();
                              logout();
                            }}
                          />
                        </Menu>
                      </Dropdown.View>
                    </Dropdown>
                  </div>
                ) : (
                  <Button
                    text="로그아웃"
                    className="rounded-full filled-primary-1 px-4 h-10"
                    onClick={() => {
                      localStorage.clear();
                      logout();
                    }}
                  />
                )}
                <Button
                  to="/parent"
                  text="부모님"
                  className="rounded-full filled-gray-800 px-4 h-10"
                />
              </>
            )}
            {authenticated && role === Role.TEACHER && me && (
              <div className="flex space-x-2 items-center">
                <span>{me.name}</span>
                <Dropdown>
                  <button className="border-2 border-secondary-5 rounded-full wh-10">
                    <Avatar src={apiUrl(me.avatar)} className="wh-8" />
                  </button>
                  <Dropdown.View className="right-0">
                    <Menu>
                      <Menu.Item text="마이 페이지" to="/teacher/mypage" />
                      <Menu.Item
                        text="로그아웃"
                        onClick={() => {
                          localStorage.clear();
                          logout();
                        }}
                      />
                    </Menu>
                  </Dropdown.View>
                </Dropdown>
              </div>
            )}
            {authenticated && role === Role.ADMIN && (
              <Button
                text="로그아웃"
                className="px-4 h-10 outlined-gray-600"
                onClick={() => {
                  localStorage.clear();
                  logout();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface TopNavBarLinkProps {
  to?: string;
  onClick?: () => void;
}

const TopNavBarLink: FC<TopNavBarLinkProps> = ({
  children,
  to = '/',
  onClick,
}) => {
  const { pathname } = useLocation();
  return onClick ? (
    <div
      className={`cursor-pointer ${pathname.startsWith(to) ? 'font-bold' : ''}`}
      onClick={onClick}
    >
      {children}
    </div>
  ) : (
    <Link to={to} className={`${pathname.startsWith(to) ? 'font-bold' : ''}`}>
      {children}
    </Link>
  );
};
